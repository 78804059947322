import { Link } from 'react-router-dom'
import css from '../../css/Public/NavBar.module.css'
import LogoImg from '../../global/WisetechInterglobe Logo3.png'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { UserContext } from '../../Class/UserPara'
import DeafultLoadingBar from '../LoadingBar/DefaultLoadingBar'
import AlertMessage from '../LoadingBar/AlertMessage'
import VerificationInput from 'react-verification-input'
import axios from 'axios'
import { Cookies } from 'react-cookie'
import { IToken } from '../../Interface/interface'
import CryptoJS from 'crypto-js';
import { SystemContext } from '../../Class/SystemPara'
const cookies = new Cookies();

const Menu = [
    { name: '首頁', link: '/', icon: '' },
    { name: '產品介紹', link: '/ProductIntro', icon: '' },
    { name: '解決方案', link: '/Solution', icon: '' },
    // { name: '立即報價', link: '/GetPrice', icon: '' },
    { name: '關於我們', link: '/About-Us', icon: '' },
    { name: '聯絡我們', link: '/Contact-Us', icon: '' },
    // { name: '技術支援', link: '/TechnicalSupport', icon: '' },
    // { name: '最新消息', link: '/News', icon: '' },
]

const NavBarV2 = () => {

    const systemContext = useContext(SystemContext);
    const { ACIDList } = useContext(UserContext)
    const { setACIDList } = useContext(UserContext);

    const [isLoginMode, setIsLoginMode] = useState(false)

    // User Login Information
    const [isLoginID, setLoginID] = useState<string>('');
    const [isPassword, setPassword] = useState<string>('');
    const [isVerifyCode, setVerifyCode] = useState<string>('');
    const [rememberMe, setRememberMe] = useState(false)
    const [savedAccounts, setSavedAccounts] = useState<string[]>([]);

    // UI Status
    const [isShowQuickLogin, setShowQuickLogin] = useState<boolean>(false);


    // Login Page Stutus
    const [isLoginStatus, setLoginStatus] = useState<number>(0);
    const [isLoginMessage, setLoginMessage] = useState<string>('');

    // Alter Message
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');

    useEffect(() => {
        if (isVerifyCode.length === 6) {
            VerifyCodeLogin();
            // LocalVerifyLogin();
        }
    }, [isVerifyCode])

    useEffect(() => {
        if (!isShowAlert) return;
        setTimeout(() => {
            setShowAlert(false);
            setAlertMessage('');
        }, 8000);
    }, [isShowAlert])

    useEffect(() => {
        const accounts = cookies.get('userAccounts');
        if (accounts) {
            try {
                // Check if accounts is a valid JSON string
                JSON.parse(accounts.toString());
                // If it's a valid JSON string, parse it
                const parsedAccounts = JSON.parse(accounts.toString());
                if (Array.isArray(parsedAccounts)) {
                    setSavedAccounts(parsedAccounts);
                } else if (typeof parsedAccounts === "string") {
                    // If parsedAccounts is a string, split it by commas and set the resulting array
                    setSavedAccounts(parsedAccounts.split(','));
                }
            } catch (error) {
                // If parsing fails, assume it's a comma-separated string of account values
                console.error("Error parsing userAccounts, treating it as a comma-separated string:", error);
                setSavedAccounts(accounts.toString().split(','));
            }
        }
    }, []);

    //Function for Login
    const LoginRouter = async () => {
        if (isLoginID.length < 5) {
            setLoginMessage('您的帳號名稱不得少於5個字元');
            return;
        } else {
            setLoginMessage('');
            if (isLoginID.includes('@')) {
                SendVerifyCode();
            } else {
                setLoginStatus(2);
            }
        }
    }

    const storeUserAccountInCookies = (account: string) => {
        let accounts: string[] = [];

        if (savedAccounts.includes(account)) {
            return;
        }

        // Retrieve existing accounts from cookies
        const existingAccounts = cookies.get('userAccounts');

        if (existingAccounts) {
            try {
                // Check if existingAccounts is a valid JSON string
                JSON.parse(existingAccounts);
                // If it's a valid JSON string, parse it
                const parsedAccounts = JSON.parse(existingAccounts);
                if (Array.isArray(parsedAccounts)) {
                    accounts = parsedAccounts; // Use existing accounts if they are in an array format
                } else if (typeof parsedAccounts === 'string') {
                    // If it's a single string (not in array format), wrap it into an array
                    accounts = [parsedAccounts];
                }
            } catch (error) {
                // If parsing fails, assume it's a single account value and wrap it in an array
                console.error("Error parsing userAccounts, treating it as a single account value:", error);
                accounts = [existingAccounts];
            }
        }

        // Add the new account only if it does not already exist in the array
        if (!accounts.includes(account)) {
            accounts.push(account);
        }

        try {
            // Store the updated accounts back into the cookie in JSON array format
            cookies.set('userAccounts', JSON.stringify(accounts), {
                path: '/',
                sameSite: 'strict',
                maxAge: 60 * 60 * 24 * 7, // Set cookie to last for 7 days
            });
            console.log('Updated userAccounts in cookies:', JSON.stringify(accounts));
        } catch (error) {
            console.error("Error saving userAccounts:", error);
        }
    };

    const PasswordLogin = async () => {
        setIsLoading(true);
        const SHAPassword = CryptoJS.SHA256(isLoginID.toUpperCase() + isPassword).toString()
        console.log(SHAPassword)

        const postData = {
            ac_id: "c0211",
            login_email: isLoginID,
            password: SHAPassword
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/user/RequestLoginToken`, postData);

            if (res.status >= 200 && res.status < 300) {
                const token: IToken = res.data
                cookies.set('token', token.token, { path: '/' })
                cookies.set('ACID', "c0211")
                cookies.set('isLoginID', isLoginID)
                storeUserAccountInCookies(isLoginID);
                CheckACIDSize();
                // window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
            }
        } catch (error) {
            setAlertType('Error');
            setAlertMessage('Your username or password not match.');
            setShowAlert(true);
        } finally {
            setIsLoading(false);
        }
    }

    const SendVerifyCode = async () => {
        try {
            setIsLoading(true);
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/user/GetMailToken`, {
                login_email: isLoginID
            }, {
                withCredentials: true
            });

            if (res.status === 200) {
                localStorage.setItem("sessionToken", res.data.verification_code);
                cookies.set('sessionToken', res.data.verification_code)
                setAlertType('Info');
                setAlertMessage('The verification code has been successfully sent to your email inbox.');
                setLoginStatus(1);
            }

        } catch (error) {
            console.log(error)
            setAlertType('Error');
            setAlertMessage('Fail to send the verification code');
        } finally {
            setShowAlert(true)
            setIsLoading(false);
        }
    }

    const VerifyCodeLogin = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/user/VerifyMailToken`, {
                code: isVerifyCode,
            }, {
                withCredentials: true
            });

            if (res.status === 200) {
                setAlertType('Success');
                setAlertMessage('You have successfully logged in.');
                getUserTokenByEmail()
            }

        } catch (error) {
            setAlertType('Error');
            setAlertMessage('Error in verifying the verification code.');
            console.log(error)
        } finally {
            setShowAlert(true)
        }
    }

    const LocalVerifyLogin = async () => {
        if (localStorage.getItem("sessionToken") === isVerifyCode) {
            setAlertType('Success');
            setAlertMessage('You have successfully logged in.');
            getUserTokenByEmail()
        } else {
            setAlertType('Error');
            setAlertMessage('Error in verifying the verification code.');
            setShowAlert(true)
        }
    }

    const getUserTokenByEmail = async () => {
        try {

            const tokenres = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/user/RequestLoginTokenByEmail`, {
                login_email: isLoginID
            }, {});

            if (tokenres.status === 200) {
                cookies.set('token', tokenres.data.token)
                setAlertType('Success');
                setAlertMessage('You have successfully logged in.');
                cookies.set('ACID', "c0217")
                cookies.set('isLoginID', isLoginID)
                storeUserAccountInCookies(isLoginID);
                CheckACIDSize();
                // window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
            }

        } catch (error) {
            setAlertType('Error');
            setAlertMessage('Error in verifying the verification code.');
        } finally {
            setShowAlert(true)
        }
    }

    const CheckACIDSize = async () => {
        const LowerCase = isLoginID.toLowerCase();
        if (LowerCase === "thomasho@wisetechinterglobe.com.hk" || LowerCase === "alexchan@wisetechinterglobe.onmicrosoft.com") {
            //if (isLoginID === "thomasho@wisetechinterglobe.com.hk") {
            setACIDList(["C0211", "C0217", "C0000"]);
            window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/Auth`);
        } else {
            if (isLoginID.includes('@')) {
                setACIDList(["C0217"]);
                window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/C0217`);
            } else {
                setACIDList(["C0211"]);
                window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/C0211`);
            }
        }
    }

    const handleAccountChange = (targetID: string) => {
        // const selectedAccount = e.target.value;
        setShowQuickLogin(false);
        setLoginID(targetID);
    };

    const handleLogout = () => {
        cookies.remove('token');
        systemContext.setLoginStatus(false);
        window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/`);
    }

    const content = (
        <>
            {isLoading && <DeafultLoadingBar isACID="Default" />}
            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            <section className={css.MainNavBar}>
                <div className={css.LogoDiv}>
                    <img src={LogoImg} alt="Logo" />
                </div>
                <div className={css.NavBarDiv}>
                    {Menu.map((item, index) => (
                        <div className={css.EachMenu} key={index}>
                            <Link to={item.link}>{item.name}</Link>
                        </div>
                    ))}
                </div>

                <div className={css.LoginPanelDiv}>
                    {systemContext.systemData.isLogin === true ? (
                        <>
                            <div className={css.EachPanelItemDiv} onClick={() => handleLogout()}>
                                <i className="fi fi-br-sign-out" />
                                <p>登出</p>
                            </div>
                            <div className={css.EachPanelItemDiv} onClick={() => setIsLoginMode(!isLoginMode)}>
                                <i className="fi fi-rs-settings-sliders" />
                                <Link className={css.test} to={ACIDList[0]}>控制台</Link>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={css.EachPanelItemDiv} onClick={() => { setIsLoginMode(!isLoginMode); setLoginMessage(''); }}>
                                <i className="fi fi-br-key" />
                                <p>登入</p>
                            </div>
                        </>
                    )}



                </div>
            </section>

            <section
                className={
                    isLoginMode
                        ? `${css.LoginPanelSection} ${css.panelEnter}`
                        : `${css.LoginPanelSection} ${css.panelLeave}`
                }
            >
                {isLoginStatus === 0 && (
                    <>
                        <div className={css.LoginBodyDiv_V2}>
                            <div className={css.BodyTitle_V2}>
                                {/* <p>登入</p> */}
                                {/* <i className={`fi fi-sr-angle-small-down ${css.BodyTitleIcon_V2}`} onClick={() => setShowQuickLogin(!isShowQuickLogin)} /> */}
                            </div>

                            {isShowQuickLogin && (
                                <div className={css.QuickLoginDiv_V2}>
                                    <label>請選擇您的帳號/電郵</label>
                                    <select onChange={(e) => handleAccountChange(e.target.value)} value={isLoginID}>
                                        <option value="">請選擇</option>
                                        {savedAccounts.map((account, index) => (
                                            <option key={index} value={account}>
                                                {account}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}


                            <div className={css.BodyInput_V2} style={{ marginBottom: '30px' }}>
                                <label>請輸入您的帳號/電郵</label>
                                <input type="text" placeholder="帳號" onChange={(e: ChangeEvent<HTMLInputElement>) => setLoginID(e.target.value)} value={isLoginID} onFocus={() => setShowQuickLogin(false)} />
                            </div>

                            {isLoginMessage !== '' && (
                                <>
                                    <div className={css.AlertMessageDiv_V2}>
                                        <i className={`fi fi-rr-cross-circle ${css.AlertMessageIcon}`} />
                                        <p>{isLoginMessage}</p>
                                    </div>
                                </>
                            )}

                            <div className={css.BodyButton_V2}>
                                <button onClick={() => LoginRouter()}>繼績</button>
                            </div>

                        </div>
                    </>
                )}

                {/* Use Password to Login */}
                {isLoginStatus === 1 && (
                    <>
                        <div className={css.LoginBodyDiv_V2}>
                            <div className={css.BodyTitle_V2}>
                                <p>登入</p>
                            </div>

                            <div className={css.BodyInput_V2}>
                                <label>您的電郵</label>
                                <input type="text" placeholder="帳號" value={isLoginID} disabled={true} />
                            </div>

                            <div className={css.BodyInput_V2}>
                                <label>驗証碼</label>
                                <VerificationInput
                                    classNames={{
                                        container: "container",
                                        character: "character",
                                        characterInactive: "character--inactive",
                                        characterSelected: "character--selected",
                                        characterFilled: "character--filled",
                                    }}
                                    validChars="0-9" inputProps={{ inputMode: "numeric" }}
                                    onComplete={(code: string) => setVerifyCode(code)}
                                />
                            </div>

                            <div className={css.BodyReturnButton_V2}>
                                <button onClick={() => setLoginStatus(0)}>{`<<使用其他帳號`}</button>
                            </div>

                            <div className={css.BodyButton_V2}>
                                {/* <button onClick={() => VerifyCodeLogin()}>登入</button> */}
                                <button onClick={() => LocalVerifyLogin()}>登入</button>
                            </div>

                        </div>
                    </>
                )}

                {/* Use Password to Login */}
                {isLoginStatus === 2 && (
                    <>
                        <div className={css.LoginBodyDiv_V2} style={{ minHeight: 'px' }}>
                            {/* <div className={css.BodyTitle_V2}>
                                <p>登入</p>
                            </div> */}

                            <div className={css.BodyInput_V2}>
                                <label>您的帳號</label>
                                <input type="text" placeholder="帳號" value={isLoginID} disabled={true} />
                            </div>

                            <div className={css.BodyInput_V2}>
                                <label>密碼</label>
                                <input type="password" placeholder="" onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)} onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        PasswordLogin();
                                    }
                                }} />
                            </div>
                            <div className={css.BodyReturnButton_V2}>
                                <button onClick={() => setLoginStatus(0)}>{`<<使用其他帳號`}</button>
                            </div>

                            <div className={css.BodyButton_V2}>
                                <button onClick={() => PasswordLogin()}>登入</button>

                            </div>

                        </div>
                    </>
                )}
            </section>

            {/* {isLoginStatus === true && (
                <section className={css.LoginPanelSection} >
                    <p>Test</p>
                </section>
            )} */}

        </>
    )
    return content
}

export default NavBarV2