
import { useEffect, useState } from 'react'
import css from '../../css/C0276/Navbar.module.css'

import { Link } from 'react-router-dom'
import { IC0211NavBarProps } from '../../Interface/interface';

const NavBar: React.FC<IC0211NavBarProps> = ({ isShowMenu, handleMenu }) => {

    const [activeMenu, setActiveMenu] = useState<number | null>(null);

    const MenuItem = [
        { name: '主頁', icon: 'fi fi-rr-home', show: true, typeid: 1, Link: '/C0276' },
        { name: '功能', icon: 'fi fi-rs-function-process', show: true, typeid: 2, Link: '' },
        { name: '系統管理員', icon: 'fi fi-bs-admin-alt', show: false, typeid: 3, Link: '' },
    ]
    const SubMenuItem = [
        { name: '一步核對', icon: 'fi fi-rr-memo-circle-check', show: true, typeid: 2, Link: '/C0276/DataMatching' },

    ]

    useEffect(() => {
        if (isShowMenu === false) {
            setActiveMenu(null);
        }
    }, [isShowMenu]);

    const handleShowSubMenu = (typeid: number) => {
        handleMenu(true)
        setActiveMenu(activeMenu === typeid ? null : typeid);
    };


    const content = (
        <>
            <div className={css.NavBar}>
                {MenuItem.filter((item) => item.show === true).map((item, index) => (

                    <div key={index}>
                        {
                            item.Link.length === 0 ? (
                                <div
                                    className={isShowMenu ? css.EachItem : css.EachItemHide}
                                    onClick={() => handleShowSubMenu(item.typeid)}
                                >
                                    <i className={item.icon} />
                                    <p>{item.name}</p>
                                </div>
                            ) : (
                                <Link to={item.Link} className={css.ItemLink} key={index}>
                                    <div
                                        className={isShowMenu ? css.EachItem : css.EachItemHide}
                                        onClick={() => handleShowSubMenu(item.typeid)}
                                    >
                                        <i className={item.icon} />
                                        <p>{item.name}</p>
                                    </div>
                                </Link>
                            )
                        }

                        {/* SubMenu Display Based on Active Menu */}
                        {activeMenu === item.typeid && (
                            <div className={css.SubMenu}>
                                {SubMenuItem.filter(subitem => subitem.show && subitem.typeid === item.typeid)
                                    .map((subitem, subindex) => (
                                        <Link to={subitem.Link} className={css.SubItemLink} key={subindex}>
                                            <div className={css.SubItem} key={subindex}>
                                                <i className={subitem.icon} />
                                                <p>{subitem.name}</p>
                                            </div>
                                        </Link>
                                    ))
                                }
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    )

    return content
}

export default NavBar