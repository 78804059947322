import { Helmet, HelmetProvider } from "react-helmet-async"
import DeafultLoadingBar from "../../Components/LoadingBar/DefaultLoadingBar";
import { useEffect, useState } from "react";

import css from '../../css/C0276/DashboardLayout.module.css'
import { Outlet } from "react-router-dom";
import NavBar from "../../Components/C0276/NavBar";

const DashboardLayout = () => {

    const [isloading, setLoading] = useState<boolean>(false);
    const [isShowMenu, setShowMenu] = useState(false)
    const [isTestModeAlertShow, setTestModeAlertShow] = useState(false)

    useEffect(() => {
        handlecheckTestMode();
    }, [])

    const handlecheckTestMode = () => {
        if (process.env.REACT_APP_API_URL === "https://wisetechinterglobe-test-b8dhe2erakdnd4cq.eastasia-01.azurewebsites.net") {
            setTestModeAlertShow(true);
        }
    }

    const handleMenu = (isMenuVisible: boolean) => {
        setShowMenu(isMenuVisible)
    }
    const content = (
        <>
            <body>
                <HelmetProvider>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>??? - 控制台</title>
                    </Helmet>
                    {isloading && <DeafultLoadingBar />}
                    {isTestModeAlertShow &&
                        <div className={css.TestModeAlert}>
                            <p>你正在使用測試版本</p>
                            <i className="fi fi-br-times-hexagon" onClick={() => setTestModeAlertShow(false)} />
                        </div>
                    }
                    <div className={ isShowMenu ? css.Layout : css.HideLayout }>
                        <div className={css.NavBar} onClick={() => handleMenu(true)}>
                            <NavBar isShowMenu={isShowMenu} handleMenu={handleMenu} />
                        </div>
                        <div className={css.Outlet} onClick={() => setShowMenu(false)}>
                            <Outlet />
                        </div>
                    </div>
                </HelmetProvider>
            </body>
        </>
    )

    return content
}

export default DashboardLayout