import { useState } from "react";
import AlertMessage from "../../Components/LoadingBar/AlertMessage";
import DeafultLoadingBar from "../../Components/LoadingBar/DefaultLoadingBar";
import readXlsxFile from 'read-excel-file'

import css from '../../css/C0276/DataMatching.module.css'
import { IC0276StockRecord } from "../../Interface/interface";

const DataMatchingPage = () => {

    const [isloading, setLoading] = useState<boolean>(false);
    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');

    const [isStockRecord, setStockRecord] = useState<IC0276StockRecord[]>([]);

    const handleinputFile = () => {
        const input = document.getElementById('input') as HTMLInputElement;
        if (input === null) {
            console.error("Input element not found");
            return null;
        }
        input.click();
    }

    // const input = document.getElementById('input') as HTMLInputElement

    // if (input === null) {
    //     console.error("Input element not found");
    //     return null;
    // }

    // input.addEventListener('change', () => {
    //     let getStartRow = 0;
    //     let getEndRow = 0;
    //     if (input.files && input.files.length > 0) {
    //         readXlsxFile(input.files[0]).then((rows) => {
    //             // `rows` is an array of rows
    //             // each row being an array of cells.
    //             console.log(rows);
    //         })

    //     }
    // })

    const content = (
        <>
            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            {isloading && <DeafultLoadingBar />}

            <section className={css.TopSection}>
                <p>一步核對</p>
            </section>

            <section className={css.MainSection}>
                <input type="file" id="input" onChange={() => handleinputFile()}/>
            </section>

        </>
    )

    return content
}

export default DataMatchingPage